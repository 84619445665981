import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'error'];

  initialize() {
    this.validEvent = new CustomEvent('valid');
    this.invalidEvent = new CustomEvent('invalid');
    this.minLength = 8;
  }

  dispatchValidityEvent() {
    const {
      matchingPasswordsErrorMsg,
      validEvent,
      invalidEvent,
      checkPasswords,
    } = this;
    const { target } = event;
    const valid = checkPasswords();
    matchingPasswordsErrorMsg(valid);
    valid
      ? target.dispatchEvent(validEvent)
      : target.dispatchEvent(invalidEvent);
  }

  checkPasswords = () => {
    const { inputTargets, setCustomValidation, minLength } = this;
    setCustomValidation();
    return (
      inputTargets[0].value === inputTargets[1].value &&
      inputTargets[0].value.length >= 8 &&
      inputTargets[1].value.length >= minLength
    );
  };

  matchingPasswordsErrorMsg = (valid) => {
    const { errorTarget } = this;
    const errorMsg = "Passwords don't match";
    valid
      ? (errorTarget.textContent = '')
      : (errorTarget.textContent = errorMsg);
  };

  setCustomValidation = () => {
    const { inputTargets, minLength } = this;
    if (
      inputTargets[0].value === inputTargets[1].value &&
      inputTargets[1].value.length >= minLength
    ) {
      inputTargets[1].setCustomValidity('');
    } else {
      inputTargets[1].setCustomValidity('Passwords do not satisfy constraints');
    }
  };
}
