import { Controller } from 'stimulus';
import {
  USERNAME_MIN_LENGTH_MESSAGE,
  EMAIL_IS_NOT_VALID_MESSAGE,
  EMAIL_IS_REQUIRED_MESSAGE,
} from '../../lib/constants/signup';

export default class extends Controller {
  static targets = ['error'];

  initialize() {
    this.validEvent = new CustomEvent('valid');
  }

  input() {
    const { target } = event;
    return target.validity.valid;
  }

  getErrorMessage(target) {
    const { id } = target;
    let message;
    switch (id) {
      case 'user_email':
      case 'email_catch':
        if (target.value.length > 0) {
          message = EMAIL_IS_NOT_VALID_MESSAGE;
        } else {
          message = EMAIL_IS_REQUIRED_MESSAGE;
        }
        break;
      case 'user_password':
        message = USERNAME_MIN_LENGTH_MESSAGE;
        break;
      default:
        message = '';
    }
    return message;
  }

  setError(target) {
    const { errorTarget } = this;
    const message = this.getErrorMessage(target);
    errorTarget.textContent = message;
    target.setCustomValidity(message);
  }

  isValid(target) {
    const pattern = new RegExp(target.pattern);
    return pattern.test(target.value);
  }

  revalidateInput() {
    // handles html5 validation bug where validity doesn't seem to work if error message is added
    // for email
    const { target } = event;
    if (target.checkValidity()) {
      target.dispatchEvent(this.validEvent);
      return; // only check on invalid inputs
    }
    if (this.isValid(target)) {
      this.errorTarget.textContent = '';
      target.setCustomValidity('');
    } else {
      this.setError(target);
    }
    target.dispatchEvent(this.validEvent);
  }

  revalidateAndEmptyInput() {
    // for password
    const { errorTarget } = this;
    if (errorTarget.textContent.length > 0) {
      errorTarget.textContent = '';
    }
    this.revalidateInput();
  }
}
