import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (window.opener) this.element.classList.add('visible');
  }

  closeWindow() {
    window.close();
  }
}
