import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submit'];

  connect() {
    this.enableIfValid();
  }

  enableIfValid() {
    const { element, submitTarget } = this;
    element.checkValidity()
      ? submitTarget.removeAttribute('disabled')
      : submitTarget.setAttribute('disabled', true);
  }

  disable() {
    const { submitTarget } = this;
    submitTarget.setAttribute('disabled', true);
  }

  hideRevealEmail() {
    const { element } = this;
    const input = element.querySelectorAll('.hide-reveal')[0];
    const eyeOpen = element.querySelectorAll("button[id='eye-open']")[0];
    const eyeClosed = element.querySelectorAll("button[id='eye-closed']")[0];

    if (input.type === 'password') {
      input.type = 'text';
      eyeClosed.classList.add('hide');
      eyeOpen.classList.remove('hide');
      eyeOpen.focus();
    } else {
      input.type = 'password';
      eyeOpen.classList.add('hide');
      eyeClosed.classList.remove('hide');
      eyeClosed.focus();
    }
  }
}
