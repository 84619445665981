import { Controller } from 'stimulus';
import { debounce } from 'lodash';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import wmFetch from '../../lib/wm_fetch';
import { SINGLE_CHAR_ERROR_MESSAGE } from '../../lib/constants/signup';

export default class extends Controller {
  static targets = ['error', 'input', 'hint'];

  initialize() {
    this.validEvent = new CustomEvent('valid');
    this.validate = debounce(() => this.validateField(), 500, {
      trailing: true,
    });
  }

  async validateField() {
    const { checkValidField, errorTarget } = this;
    if (this.inputTarget.value.length === 1) {
      this.showSingleCharacterErrorMsg(errorTarget);
      return;
    }
    const { available, message } = await checkValidField(
      this.inputTarget.value,
    );
    const msg = available ? '' : message;
    errorTarget.textContent = msg;
    msg === '' ? this.showUsernameHint() : this.hideUsernameHint();
    this.inputTarget.setCustomValidity(msg);
    this.checkValidity(this.inputTarget);
  }

  checkValidField = (input) => {
    const url = `${window.api_gateway_core}${this.data.get('url')}${input}`;
    return wmFetch(url).then((res) => res.json());
  };

  checkValidity(target) {
    target.dispatchEvent(this.validEvent);
  }

  hideUsernameHint() {
    const { hintTarget } = this;
    hintTarget.classList.add('hide');
  }

  showUsernameHint() {
    const { hintTarget } = this;
    hintTarget.classList.remove('hide');
  }

  showSingleCharacterErrorMsg(errorTarget) {
    this.hideUsernameHint();
    errorTarget.textContent = SINGLE_CHAR_ERROR_MESSAGE;
    this.inputTarget.setCustomValidity(SINGLE_CHAR_ERROR_MESSAGE);
    this.checkValidity(this.inputTarget);
  }
}
