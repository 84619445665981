import { throttle } from './';

function promisify(cb) {
  return new Promise((resolve) => resolve(cb()));
}

function handleReCaptchaPositioning() {
  // default attributes
  const recapbadge = document.querySelector('.grecaptcha-badge');
  if (!recapbadge) return;
  const initialPosition = recapbadge.style.position;
  const parentDiv = recapbadge.parentElement;
  parentDiv.style.display = 'flex';
  parentDiv.style.justifyContent = 'center';
  parentDiv.style.margin = '8px 0';
  const cancelBtn = document.querySelector('a[name="cancel"]');
  if (cancelBtn) {
    cancelBtn.parentNode.insertBefore(parentDiv, cancelBtn.nextSibling);
  }

  const positionRecaptchaMobile = () => {
    const MOBILE_SIZE = 940;
    const updatedBadge = document.querySelector('.grecaptcha-badge');
    if (window.innerWidth >= MOBILE_SIZE) {
      updatedBadge.style.position = initialPosition;
    } else {
      updatedBadge.style.position = 'initial';
    }
  };
  positionRecaptchaMobile();
  window.onresize = throttle(positionRecaptchaMobile);
}

const updateSubmitBtn = () => {
  const submitButton = document.querySelector('[type="submit"]');
  if (!submitButton) return;
  submitButton.setAttribute('disabled', true);
};

window.onload = () => {
  const recapbadge = document.querySelector('.grecaptcha-badge');
  if (!recapbadge) return;
  promisify(handleReCaptchaPositioning).then(() => updateSubmitBtn());
};
