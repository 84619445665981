import Swal from 'sweetalert2';

const Modal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-lg',
    cancelButton: 'btn btn-default btn-lg mr-3',
    input: 'form-control',
  },
  buttonsStyling: false,
  reverseButtons: true,
  showCancelButton: true,
});

const ConfirmDialog = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-lg',
    cancelButton: 'btn btn-default btn-lg mr-3',
    input: 'form-control',
    popup: 'confirmation-dialog',
  },
  buttonsStyling: false,
  reverseButtons: true,
  showCancelButton: true,
  title: 'Confirm Action',
  confirmButtonText: 'Yes, continue',
  cancelButtonText: 'No, cancel',
});

export { Modal, ConfirmDialog };
