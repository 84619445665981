// Rails unobtrusive javascript driver
import Rails from '@rails/ujs';
import { ConfirmDialog } from './modals';

Rails.confirm = (message, element) => {
  ConfirmDialog.fire({
    // With some extra data attributes, we can leverage more features
    // from SweetAlert2 like titles, images, etc. The text content also needs
    // a little cleanup, to avoid duplicate "Are you sure?" strings.
    title: element.dataset.confirmTitle || 'Confirm Action',
    confirmButtonText: element.dataset.confirmButtonText || 'Yes, continue',
    cancelButtonText: element.dataset.cancelButtonText || 'No, cancel',
    showConfirmButton: element.dataset.hideConfirmButton !== 'true',
    html: message,
  }).then(({ value, isConfirmed, isDismissed }) => {
    if (value) {
      // If the user clicks the confirm button, we'll temporarily stub this method
      // with one that always returns true, click the original element, and then
      // restore the original method. Feels a bit weird to me, but I took this
      // from the commit that introduced this ability in the first place:
      // https://github.com/rails/rails/commit/e9aa7ec#commitcomment-30083492
      const old = Rails.confirm;
      Rails.confirm = () => {
        return true;
      };
      element.click();
      Rails.confirm = old;
    }
    // If the user clicks the confirm button we dispatch an event to listen
    // to in order to take action after confirm. `isConfirmed` comes from
    // callbacks in SweetAlert2. This is used in
    // `app/javascript/new_admin/controllers/submittable_controller.js`
    if (isConfirmed) {
      const confirmEvent = new CustomEvent('confirmation_dialog:confirmed', {
        bubbles: true,
      });
      element.dispatchEvent(confirmEvent);
    }
    // Works similar to the `isConfirmed` above. Implemented by SweetAlert2.
    // This is used to toggle the checkbox back to an false state when dismissed
    if (isDismissed) {
      const dismissedEvent = new CustomEvent('confirmation_dialog:dismissed', {
        bubbles: true,
      });
      element.dispatchEvent(dismissedEvent);
    }
  });
  // SweetAlert returns an async promise, but Rails expects a sync response like
  // JS's confirm() function. So we always return false when triggering the modal
  // to prevent navigation before the user interacts with it.
  return false;
};

Rails.start();
