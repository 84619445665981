import { Controller } from 'stimulus';

export default class extends Controller {
  revealSignupForm() {
    document.querySelector('.signup-container').classList.remove('hide');
    document.querySelector('.signup').classList.add('hide');
    document.querySelector('#user_email').value =
      document.querySelector('#email_catch').value;
    document.querySelector('#user_email').focus();
    if (typeof analytics === 'undefined') {
      return;
    }
    analytics.page('Signup with Email', {
      campaign: {},
      context_event_location: 'Signup with Email',
      path: '/signup',
      search: '',
      url: window.location.href,
    });
  }
}
