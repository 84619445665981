import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import '../lib/rails';
import '../auth/controllers/util/recaptcha';

const application = Application.start();
const context = require.context(
  '../auth/controllers',
  true,
  /^(?!.*test\.js).*\.js$/,
);
application.load(definitionsFromContext(context));
