export default function wmFetch(url, params = {}) {
  window.fetchActive = window.fetchActive || 0;
  window.fetchActive++;
  const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
  let mergedParams = params;
  if (csrfTokenElement) {
    mergedParams = {
      ...params,
      headers: {
        'X-CSRF-Token': csrfTokenElement.content,
        ...params.headers,
      },
    };
  }

  let urlObject;
  const pxCookie = window.localStorage.getItem('pxcookie');
  try {
    urlObject = new URL(url);
  } catch (e) {
    // Swallow error if not a valid URL
  }
  if (pxCookie && urlObject?.hostname.startsWith('api-g')) {
    mergedParams = {
      ...mergedParams,
      headers: {
        'X-Px-Cookies': pxCookie,
        ...mergedParams.headers,
      },
    };
  }

  return fetch(url, mergedParams)
    .then((res) => {
      window.fetchActive--;
      return res;
    })
    .catch((err) => {
      window.fetchActive--;
      throw err;
    });
}
