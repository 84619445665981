export function throttle(f, delay = 500) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = window.setTimeout(function () {
      f.apply(context, args);
    }, delay);
  };
}
