import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'login', 'error'];

  connect() {
    this.updateInitialValidInputs();
  }

  updateInitialValidInputs() {
    const { inputTargets, errorTargets } = this;
    let index = 0;
    errorTargets.forEach(({ textContent }) => {
      if (textContent.length) {
        inputTargets[index++].setCustomValidity(textContent);
        this.showLoginSpan();
      }
    });
  }

  updateValidMessages() {
    const { inputTargets, errorTargets } = this;
    let index = 0;
    inputTargets.forEach((input) => {
      errorTargets[index++].textContent = '';
      input.id === 'user_email' ? this.hideLoginSpan() : null;
    });
  }

  hideLoginSpan() {
    const { loginTarget } = this;
    loginTarget.classList.add('hide');
  }

  showLoginSpan() {
    const { loginTarget } = this;
    loginTarget.classList.remove('hide');
  }
}
